import { Box } from '@mui/material';
import { OnHoverToolTip } from '@/common/index';
import { greaterThanPrecision, formatAmount } from '@/utils/index';

const TokenAmount = ({ amount, tipPlacement = 'top', token, precision }) => {
  const displayPrecision = token === 'USDC' ? 2 : precision || 6;
  const decimalPrecision = amount?.toString().split('.')[1]?.length || displayPrecision;

  return (
    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
      {token !== 'USDC' && greaterThanPrecision(amount, displayPrecision) ? (
        <OnHoverToolTip
          hoverText={formatAmount(amount, decimalPrecision)}
          width={300}
          placement={tipPlacement}
          displayText={`${formatAmount(amount, displayPrecision)}... ${token}`}
        />
      ) : (
        `${formatAmount(amount, displayPrecision)} ${token}`
      )}
    </Box>
  );
};

export default TokenAmount;
